import { useQuery } from "react-query";
import { ConfigContainer } from "../contexts/config/ConfigContextInterface";
import { httpClient } from "../httpClient";
import { CompanyBizWebDetails } from "../models/companyBizWebDetails";

export function useDetailsFromBizWeb(config: ConfigContainer, companyNo: string | undefined) {
    return useQuery<CompanyBizWebDetails | undefined, Error>('company-details-bizweb',
    () => fetchDetailsFromBizWeb(config, companyNo),
    {
        onSuccess(data: CompanyBizWebDetails | undefined) {
            if (data) {
                data.numberOfEmployees = processNumberOfEmployees(data.numberOfEmployees);
            }

            return data;
        },
        keepPreviousData: true,
        enabled: !!companyNo
    });
}

async function fetchDetailsFromBizWeb(config: ConfigContainer, companyNo: string | undefined) {
    const url = `${config.backendApiUrl}/externalcompanyinfo/${companyNo}`;
    
    var result = await httpClient.get<CompanyBizWebDetails>(url, config, "json");

    return result.data;
}

function processNumberOfEmployees(noOfEmployees: string | number | undefined) : number | undefined {
    if (noOfEmployees === undefined || isNaN(+noOfEmployees)) {
        return undefined;
    }

    return Number(noOfEmployees);
}