import { ConfigContainer } from "./contexts/config/ConfigContextInterface";
import axios, { AxiosResponse, ResponseType } from 'axios';

export const httpClient = {
    async get<T>(url: string, config: ConfigContainer, responseType?: ResponseType): Promise<AxiosResponse<T>> {
        const accessToken = getAccessToken(config.oidcConfiguration.authority, config.oidcConfiguration.clientId);
        const headers = {
            'authorization': 'Bearer ' + accessToken
        };

        const response = await axios.get(url, {
            headers: headers,
            responseType: responseType ?? undefined
        })
        .catch(error => {
            throw Error(error.message);
        });

        return response;
    }
}

export function getAccessToken(authority: string, clientId: string): string | null {
    let oidcUserJson = sessionStorage.getItem(`oidc.user:${authority}:${clientId}`);

    if (oidcUserJson === null) {
        return oidcUserJson;
    }

    let oidcUser = JSON.parse(oidcUserJson);
    let accessToken = oidcUser.access_token;

    return accessToken;
}