import { CompanyInfo } from "@vbc/biz-web-client-library";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CompanyBizWebDetails } from "../../models/companyBizWebDetails";
import CompanyChoiceComponent from "./companyChoice/CompanyChoice";
import DetailsTableComponent from "./companyDetailsTable/DetailsTable";

interface HeaderProps {
    providedCompany: CompanyInfo
}

interface Tab {
    id: number,
    name: string,
    content: JSX.Element,
    disabled: boolean
}

interface TabsState {
    activeTabId: number,
    availableTabs: Tab[]
}

export default function TabsComponent(props: HeaderProps) {
    const { t } = useTranslation();
    const [selectedCompany, setSelectedCompany] = useState<CompanyBizWebDetails>();
    const [searchQuery, setSearchQuery] = useState<string>("");

    function companyHasBeenSelectedCallback(companyInfo: CompanyBizWebDetails) {
        setSelectedCompany({...companyInfo});
    }

    function setSearchQueryCallback (query: string) {
        setSearchQuery(query);
    }

    let availableTabs: Tab[] = [
        {
            id: 0,
            name: "chooseCompany",
            disabled: false,
            content: <CompanyChoiceComponent VBNXTDetails={props.providedCompany} companyHasBeenSelected={companyHasBeenSelectedCallback} searchQuery={searchQuery} searchHasBeenChanged={setSearchQueryCallback}></CompanyChoiceComponent>
        },
        {
            id: 1,
            name: "companyDetails",
            disabled: true,
            content: <DetailsTableComponent VBNXTDetails={props.providedCompany} bizWebCompanyNumber={selectedCompany?.number}></DetailsTableComponent>
        }
    ];

    const [state, setState] = useState<TabsState>({
        activeTabId: 0,
        availableTabs: availableTabs
    });

    const handleGoBackButton = useCallback((event: MessageEvent) => {
        if (event.data.bizWebMessageType === 'goBackToCompanyList') {
            let newState = { ...state };
            newState.activeTabId = 0;
            setState(newState);
        }
    }, []);

    useEffect(() => {
        window.addEventListener('message', (e) => handleGoBackButton(e));

        return window.removeEventListener('message', (e) => handleGoBackButton(e));
    }, [handleGoBackButton]);

    useEffect(() => {
      let newState = { ...state };
      if (selectedCompany) {
        newState.availableTabs = state.availableTabs.map(tab => {
            tab.disabled = false; 
            return tab;
        });
        newState.activeTabId = 1;
      }

      setState(newState);
    }, [selectedCompany]);

    function changeActiveTab(tab: Tab) {
        let newState = { ...state };
        newState.activeTabId = tab.id;
        setState(newState);
    }

    function renderAvailableTabs() {
        const tabButtons = (state.availableTabs?.map((item, index) =>
        <li data-testid={item.id} key={item.id} className={`nav-item ${item.disabled ? "disabled" : ""}`}>
            <h3>
            <a id={item.id.toString()} tabIndex={item.id} role="tab" data-toggle="tab" aria-controls="normal" aria-selected="false" onClick={() => changeActiveTab(item)}>
                {t(item.name)}
            </a>
            </h3>
        </li>
        ));

        return (
            <ul className="nav nav-tabs nav-tabs-primary" role="tablist" aria-label="Tabs">
                {tabButtons}
            </ul>
        );
    }

    return (<div aria-label="tabs">
        <div>
            {renderAvailableTabs()}
        </div>
            <div>
            {availableTabs.find(item => item.id === state.activeTabId)?.content}
            </div>
        </div>);
}