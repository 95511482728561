import { FC, PropsWithChildren, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthProvider } from 'react-oidc-context';
import { User } from 'oidc-client-ts';
import { ConfigContext } from '../config/ConfigContext';

type AuthContainer = {
    onSigninCallback: (user: User | void) => Promise<void> | void;
    authority: string;
    client_id: string;
    redirect_uri: string;
    scope: string;
    response_type: string;
    automaticSilentRenew: boolean;
}

export const AuthWrapper: FC<PropsWithChildren> = ({ children }) => {
    const navigate = useNavigate();
    const config = useContext(ConfigContext);

    const oidcConfig : AuthContainer = {
        authority: config.oidcConfiguration.authority,
        client_id:  config.oidcConfiguration.clientId,
        scope: config.oidcConfiguration.scope,
        redirect_uri: `${window.location.origin}`,
        response_type: 'code',
        onSigninCallback: (user) => {
            navigate(user?.state as string, { replace: true });
        },
        automaticSilentRenew: false
    };

    return (
        <AuthProvider {...oidcConfig}>
            {children}
        </AuthProvider>
    );
};

export default AuthWrapper;