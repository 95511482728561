import '@vismaux/vud/dist/css/vud.light-dark.mode.min.css';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import Router from './routing/Router';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ConfigProvider } from './contexts/config/ConfigContext';
import AuthWrapper from './contexts/auth/AuthWrapper';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import I18NextHttpBackend from 'i18next-http-backend';
import { Suspense } from 'react';

i18next
    .use(initReactI18next)
    .use(I18NextHttpBackend)
    .init({
        backend: { loadPath: "/translations/{{lng}}.json"},
        lng: "en-GB",
        fallbackLng: "en-GB",
        interpolation: { escapeValue: false },
    })
    
const queryClient = new QueryClient();

function App() {
  return (
    <div className="App">
      <Suspense fallback={"Loading..."}>
        <QueryClientProvider client={queryClient}>
          <ConfigProvider>
            <BrowserRouter>
              <AuthWrapper>
                <Router />
              </AuthWrapper>
            </BrowserRouter>
          </ConfigProvider>
        </QueryClientProvider>
      </Suspense>
    </div>
  );
}

export default App;
