import { useCallback, useEffect, useState } from 'react';
import { CompanyInfo } from '@vbc/biz-web-client-library';
import { crossCommunicationClient } from '../../../crossCommunicationClient';
import TabsComponent from '../../../components/tabs/Tabs';
import LoadingComponent from '../../../components/loading/LoadingComponent';
import { useTranslation } from 'react-i18next';
import i18n from "i18next";

export default function LandingPage() {
    const [companyInfo, setCompanyInfo] = useState<CompanyInfo>();
    const { t } = useTranslation();

    const handleProvidedCompany = useCallback((event: MessageEvent) => {
        if (event.data.bizWebMessageType === 'setInitialData') {
            setCompanyInfo(event.data.companyInfo);
            i18n.changeLanguage(event.data.locale ?? 'en-GB');
        }
    }, []);

    useEffect(() => {
        crossCommunicationClient.notifyAppReady();

        window.addEventListener('message', (e) => handleProvidedCompany(e));

        return window.removeEventListener('message', (e) => handleProvidedCompany(e));
    }, [handleProvidedCompany]);

    return (
        <div>
            {
                companyInfo ? 
                <TabsComponent providedCompany={companyInfo}></TabsComponent> :
                <LoadingComponent spinnerText={t("loading")}></LoadingComponent>
            }
        </div>
    )
}
