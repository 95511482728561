import { httpClient } from "../httpClient";
import { useQuery } from "react-query";
import { CompanyBizWebDetails } from "../models/companyBizWebDetails";
import { ConfigContainer } from "../contexts/config/ConfigContextInterface";

export function useAvailableCompanies(config: ConfigContainer, search: string) {
    return useQuery<CompanyBizWebDetails[] | undefined, Error>(['available-companies', search],
    () => fetchAvailableCompanies(config, search),
    {
        keepPreviousData: true,
        enabled: !!search
    });
}

async function fetchAvailableCompanies(config: ConfigContainer, search: string) {
    const url = `${config.backendApiUrl}/externalcompanyinfo?searchWord=${search}`;
    
    var result = await httpClient.get<CompanyBizWebDetails[]>(url, config, "json");

    return result.data;
}