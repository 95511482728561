import { Routes, Route, useLocation } from 'react-router-dom';
import LandingPage from './pages/landing/LandingPage';
import { hasAuthParams, useAuth } from 'react-oidc-context';
import { useEffect } from 'react';
import LoadingOverlayComponent from '../components/loading/LoadingComponent';

export default function Router() {
    const auth = useAuth();
    const location = useLocation();
    
    useEffect(() => {
        if (!hasAuthParams() &&
            !auth.isAuthenticated &&
            !auth.activeNavigator &&
            !auth.isLoading) {
            auth.signinRedirect({ state: location.pathname + location.search });
        }
    
        return auth.events.addAccessTokenExpiring(() => {
            auth.signinSilent();
        });
    
      }, [auth, location]);

    if (auth.isAuthenticated) {
        return (
            <div>
                <Routes>
                    <Route path="*"/>
                    <Route path="/" element={ <LandingPage /> }/>
                </Routes>
            </div>
        );
    } else {
        return (
            <div>
                <Routes>
                    <Route path="*" element={ <LoadingOverlayComponent spinnerText='Authenticating'/> }/>
                </Routes>
            </div>
        )
    }

}
