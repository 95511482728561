import { CompanyInfo } from '@vbc/biz-web-client-library';
import { useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfigContext } from '../../../contexts/config/ConfigContext';
import { crossCommunicationClient } from '../../../crossCommunicationClient';
import { useDetailsFromBizWeb } from '../../../hooks/useDetailsFromBizWeb';
import { Fields } from '../../../models/companyDetailsState';
import LoadingComponent from '../../loading/LoadingComponent';

import styles from './DetailsTable.module.css';
import { updateFieldFromBNXT, updateFieldFromBizWeb } from '../../../mappings/companyDetailsModelMapping';

interface HeaderProps {
    bizWebCompanyNumber: string | undefined,
    VBNXTDetails: CompanyInfo,
}

export default function DetailsTableComponent(props: HeaderProps) {
    const { t } = useTranslation();
    const companyInfoRef = useRef({...props.VBNXTDetails});
    
    const config = useContext(ConfigContext);
    
    const { data, isError, isLoading } = useDetailsFromBizWeb(config, props.bizWebCompanyNumber);

    function toggleRow(rowName: Fields, rowEnabled: boolean) {
        if (!data || isError) {
            console.error("Unexpected error: BizWeb details are not loaded.");
            return;
        }

        if (rowEnabled) {
            companyInfoRef.current = updateFieldFromBizWeb(rowName, data, companyInfoRef.current);
        } else {
            companyInfoRef.current = updateFieldFromBNXT(rowName, props.VBNXTDetails, companyInfoRef.current);
        }

        crossCommunicationClient.notifyCompanyModified(companyInfoRef.current);
    }

    function toggleAllRows(checkbox: HTMLInputElement) {
        const radioButtons = document.querySelectorAll<HTMLInputElement>('.child-radio');
        radioButtons.forEach(radioButton => { 
            if (radioButton.disabled === false) {
                radioButton.checked = checkbox.checked;
                toggleRow(radioButton.id as Fields, radioButton.checked);
            }
        });
    }

    function displayRow(rowName: Fields, bizWebValue: string | number | boolean | undefined, vbnxtValue: string | number | boolean | undefined, canChange: boolean) {
        return (<tr className={`${styles.row} ${styles.activeRow}`}>
            <td>{t([rowName])}</td>
            <td aria-label={`row-bizweb-${rowName}`}>{renderValue(bizWebValue)}</td>
            <td aria-label={`row-vbnxt-${rowName}`}>{renderValue(vbnxtValue)}</td>
            <td aria-label={`row-status-${rowName}`} className={`${styles.statusParent}`}>
                {
                    shouldDisplayStatus(bizWebValue, vbnxtValue) ?
                        areValuesIdentical(bizWebValue, vbnxtValue) ? 
                            <span aria-label={`uptodate-${rowName}`} className={`rounded-24 ${styles.upToDate}`}>{t("upToDate")}</span>
                            :
                            <span aria-label={`conflict-${rowName}`} className={`rounded-24 ${styles.conflict}`}>{t("conflict")}</span>
                        :
                        <span aria-label={`empty-${rowName}`}></span>
                }
            </td>
            <td>
                {(shouldDisplayStatus(bizWebValue, vbnxtValue) === false) || areValuesIdentical(bizWebValue, vbnxtValue) ? "" :
                    <div className="switch">
                        <input aria-label={`radio-${rowName}`} id={rowName} disabled={canChange === false} type="checkbox" name="child-radio" className="child-radio" onChange={e => toggleRow(rowName, e.target.checked)} />
                        <label htmlFor={rowName} className="togglemark">
                        </label>
                    </div>
                }
            </td>
        </tr>)
    }
    
    function renderValue(value: string | number | boolean | undefined) {
        if (typeof value === "boolean") {
            if (value) {
                return t("yes");
            } else {
                return t("no");
            }
        }

        return value;
    }

    function shouldDisplayStatus(bizWebValue: string | number | boolean | undefined, vbnxtValue: string | number | boolean | undefined): boolean
    {
        var bizWebHasValue = hasValue(renderValue(bizWebValue));
        var vbnxtHasValue = hasValue(renderValue(vbnxtValue));

        if (!bizWebHasValue && !vbnxtHasValue) {
            return false;
        } else if (!bizWebHasValue && vbnxtHasValue) {
            return false;
        }

        return true;
    }

    function areValuesIdentical(bizWebValue: string | number | boolean | undefined, vbnxtValue: string | number | boolean | undefined): boolean 
    {
        if (typeof bizWebValue === "string" && typeof vbnxtValue === "string") {
            return bizWebValue.toLowerCase() === vbnxtValue.toLowerCase();
        }

        return bizWebValue === vbnxtValue;
    }

    function hasValue(value: string | number | boolean | undefined) {
        return !(value === undefined || value === null || value === "");
    }

    return (
        isLoading ? <LoadingComponent spinnerText={t("bizWebDetailsLoading")}/> :
        <table aria-label='company-details' className="table">
            <thead>
                <tr>
                    <th scope="col">{t("details")}</th>
                    <th scope="col">{t("inBizWeb")}</th>
                    <th scope="col">{t("inBusinessNXT")}</th>
                    <th scope="col">{t("status")}</th>
                    <th scope="col"><div className="switch">
                        <input id="option" type="checkbox" name="option" onChange={e => toggleAllRows(e.target)}/>
                        <label htmlFor="option" className="togglemark">
                        </label>
                    </div></th>
                </tr>
            </thead>
            <tbody>
                {displayRow(Fields.CompanyNo, data?.number, props.VBNXTDetails.companyNo.value, props.VBNXTDetails.companyNo.canChange)}
                {displayRow(Fields.Name, data?.name, props.VBNXTDetails.name.value, props.VBNXTDetails.name.canChange)}
                {displayRow(Fields.PostalAddress, data?.postalAddress, props.VBNXTDetails.postalAddress.value, props.VBNXTDetails.postalAddress.canChange)}
                {displayRow(Fields.PostalZipCode, data?.postalZipCode, props.VBNXTDetails.postalZipCode.value, props.VBNXTDetails.postalZipCode.canChange)}
                {displayRow(Fields.PostalCity, data?.postalCity, props.VBNXTDetails.postalCity.value, props.VBNXTDetails.postalCity.canChange)}
                {displayRow(Fields.VisitorsAddress, data?.visitorAddress, props.VBNXTDetails.visitorAddress.value, props.VBNXTDetails.visitorAddress.canChange)}
                {displayRow(Fields.VisitorsZipCode, data?.visitorZipCode, props.VBNXTDetails.visitorZipCode.value, props.VBNXTDetails.visitorZipCode.canChange)}
                {displayRow(Fields.VisitorsCity, data?.visitorCity, props.VBNXTDetails.visitorCity.value, props.VBNXTDetails.visitorCity.canChange)}
                {displayRow(Fields.PhoneNo, data?.phone, props.VBNXTDetails.phone.value, props.VBNXTDetails.phone.canChange)}
                {displayRow(Fields.FaxNo, data?.fax, props.VBNXTDetails.fax.value, props.VBNXTDetails.fax.canChange)}
                {displayRow(Fields.EmailAddress, data?.email, props.VBNXTDetails.emailAddress.value, props.VBNXTDetails.emailAddress.canChange)}
                {displayRow(Fields.Web, data?.web, props.VBNXTDetails.webPage.value, props.VBNXTDetails.webPage.canChange)}
                {displayRow(Fields.Turnover, data?.latestAccounts_OperatingRevenues, props.VBNXTDetails.operatingIncome.value, props.VBNXTDetails.operatingIncome.canChange)}
                {displayRow(Fields.NumberOfEmployees, data?.numberOfEmployees, props.VBNXTDetails.noOfEmployees.value, props.VBNXTDetails.noOfEmployees.canChange)}
                {displayRow(Fields.InVatRegistry, data?.inVATRegistry, props.VBNXTDetails.inVatRegistry.value, props.VBNXTDetails.inVatRegistry.canChange)}
                {displayRow(Fields.VatRegNumber, data?.vatNumber, props.VBNXTDetails.vatRegNumber.value, props.VBNXTDetails.vatRegNumber.canChange)}
            </tbody>
        </table>
);
}