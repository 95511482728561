import { CompanyInfo } from "@vbc/biz-web-client-library";
import { Fields } from "../models/companyDetailsState";
import { CompanyBizWebDetails } from "../models/companyBizWebDetails";

interface FieldName {
    bnxt: keyof Omit<CompanyInfo, 'id'>,
    bizweb: keyof CompanyBizWebDetails
}

export const fieldMap: Record<Fields, FieldName> = { 
    [Fields.CompanyNo]: { bnxt: "companyNo", bizweb: "number" },
    [Fields.Name]: { bnxt: "name", bizweb: "name" },
    [Fields.PostalAddress]: { bnxt: "postalAddress", bizweb: "postalAddress" },
    [Fields.PostalZipCode]: { bnxt: "postalZipCode", bizweb: "postalZipCode" },
    [Fields.PostalCity]: { bnxt: "postalCity", bizweb: "postalCity" },
    [Fields.VisitorsAddress]: { bnxt: "visitorAddress", bizweb: "visitorAddress" },
    [Fields.VisitorsZipCode]: { bnxt: "visitorZipCode", bizweb: "visitorZipCode" },
    [Fields.VisitorsCity]: { bnxt: "visitorCity", bizweb: "visitorCity" },
    [Fields.PhoneNo]: { bnxt: "phone", bizweb: "phone" },
    [Fields.FaxNo]: { bnxt: "fax", bizweb: "fax" },
    [Fields.EmailAddress]: { bnxt: "emailAddress", bizweb: "email" },
    [Fields.InVatRegistry]: { bnxt: "inVatRegistry", bizweb: "inVATRegistry" },
    [Fields.Turnover]: { bnxt: "operatingIncome", bizweb: "latestAccounts_OperatingRevenues" },
    [Fields.Web]: { bnxt: "webPage", bizweb: "web" },
    [Fields.NumberOfEmployees]: { bnxt: "noOfEmployees", bizweb: "numberOfEmployees" },
    [Fields.VatRegNumber]: { bnxt: "vatRegNumber", bizweb: "vatNumber" }
};