import { server } from './contexts/communicationServer';
import { AppReadyMessage, CompanyInfo } from '@vbc/biz-web-client-library';

export const crossCommunicationClient = {
    notifyAppReady(): void {
        const message : AppReadyMessage = { bizWebMessageType:'ready' };
        server.notifyAppReady(message);
    },
    notifyCompanySelected(): void {
        server.notifyCompanySelected();
    },
    notifyCompanyModified(companyInfo: CompanyInfo): void {
        server.notifyCompanyModified(companyInfo);
    },
};

