import { CompanyInfo } from "@vbc/biz-web-client-library";

export enum Fields {
    CompanyNo = "companyNo",
    Name = "name",
    PostalAddress = "postalAddress",
    PostalZipCode = "postalZipCode",
    PostalCity = "postalCity",
    VisitorsAddress = "visitorAddress",
    VisitorsZipCode = "visitorZipCode",
    VisitorsCity = "visitorCity",
    PhoneNo = "phoneNo",
    FaxNo = "faxNo",
    EmailAddress = "emailAddress",
    NumberOfEmployees = "numberOfEmployees",
    Turnover = "turnover",
    Web = "web",
    InVatRegistry = "inVatRegistry",
    VatRegNumber = "vatRegNumber"
}


export default interface DetailsState {
    providedCompany: CompanyInfo,
    filteredCompanyNo: number | undefined
}