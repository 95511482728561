import { CompanyInfo } from '@vbc/biz-web-client-library';
import { ChangeEvent, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfigContext } from '../../../contexts/config/ConfigContext';
import { crossCommunicationClient } from '../../../crossCommunicationClient';
import { useAvailableCompanies } from '../../../hooks/useAvailableCompanies';
import { CompanyBizWebDetails } from '../../../models/companyBizWebDetails';
import LoadingComponent from '../../loading/LoadingComponent';

import styles from './CompanyChoice.module.css';

interface CompanyChoiceState {
    searchQuery: string,
    selectedCompany: CompanyBizWebDetails | undefined
}

interface HeaderProps {
    searchQuery: string | undefined,
    VBNXTDetails: CompanyInfo,
    companyHasBeenSelected: (company: CompanyBizWebDetails) => void;
    searchHasBeenChanged: (searchQuery: string) => void;
}

export default function CompanyChoiceComponent(props: HeaderProps) {
    const [state, setState] = useState<CompanyChoiceState>({searchQuery: props.searchQuery ?? "", selectedCompany: undefined})
    const { t } = useTranslation();

    const config = useContext(ConfigContext);
    const { data, isError, error, isLoading } = useAvailableCompanies(config, state.searchQuery);

    if (isError) {
        console.error(error); // TODO: Add better error handling
    }

    useEffect(() => {
        if (props.searchQuery) {
            return;
        }

        let newState = { ...state };
        if (props.VBNXTDetails.companyNo?.value !== undefined) {
            newState.searchQuery = props.VBNXTDetails.companyNo?.value.toString();
        } else if (props.VBNXTDetails.name?.value !== undefined) {
            newState.searchQuery = props.VBNXTDetails.name?.value.toString();
        } else {
            newState.searchQuery = ""
        }
        props.searchHasBeenChanged(newState.searchQuery);
        setState(newState);
    }, [props.VBNXTDetails]);

    function companyHasBeenSelected(company: CompanyBizWebDetails) {
        let newState = { ...state };
        newState.selectedCompany = company;
        setState(newState);
        props.companyHasBeenSelected(company);
        crossCommunicationClient.notifyCompanySelected();
    }
    
    const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        let newState = { ...state };
        newState.searchQuery = e.target.value;
        props.searchHasBeenChanged(newState.searchQuery);
        setState(newState);
    }, [props, state]);

    function filterAvailableCompanies(companies: CompanyBizWebDetails[] | undefined) {
        if (companies && companies.length) {
            return (companies?.map((item, index) =>
            <tr aria-label="table-row" key={index} onClick={() => companyHasBeenSelected(item)}>
                <td aria-label={`col-number-${index}`}>{item.number ?? "-"}</td>
                <td aria-label={`col-name-${index}`}>{item.name ?? "-"}</td>
                <td aria-label={`col-address-${index}`}>{item.visitorAddress ?? "-"}</td>
                <td aria-label={`col-zip-${index}`}>{item.visitorZipCode ?? "-"}</td>
                <td aria-label={`col-city-${index}`}>{item.visitorCity ?? "-"}</td>
                <td aria-label={`col-code-${index}`}>{item.countryCode ?? "-"}</td>
            </tr>))
        }
        else if (state.searchQuery && isLoading === false) {
            return (<tr className={`${styles.row}`}>
                        <td colSpan={6}>{t("noAvailableCompanies")}</td>
                    </tr>);
        }
        else {
            return (<tr className={`${styles.row}`}>
            <td colSpan={6}>{t("searchForCompanies")}</td>
                    </tr>);
        }
    }
   
    return (
        <div aria-label="company-choice">
            <div className="w-100">
                <div className={`form-group search-group align-self-end ${styles.searchBar}`}>
                    <label htmlFor="search-query-input" className="sr-only">{t("search")}</label>
                    <input id="search-query-input" className="form-control" type="search" placeholder="Search..." aria-label="search-input" onChange={(handleChange)} value={state.searchQuery}/>
                    <span className="search-icon">Search icon</span>
                    <span className="clear-search">Clear</span>
                </div>
            </div>
            {isLoading ? <LoadingComponent spinnerText={t("bizWebDetailsLoading")}/> :
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th aria-label="col-number" scope="col">{t("companyNo")}</th>
                            <th aria-label="col-name" scope="col">{t("name")}</th>
                            <th aria-label="col-address" scope="col">{t("postalAddress")}</th>
                            <th aria-label="col-zip" scope="col">{t("postalZipCode")}</th>
                            <th aria-label="col-city" scope="col">{t("postalCity")}</th>
                            <th aria-label="col-code" scope="col">{t("countryCode")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filterAvailableCompanies(data)}
                    </tbody>
                </table>}
        </div>
    )
}