import { CompanyInfo, FieldInfo } from "@vbc/biz-web-client-library";
import { Fields } from "../models/companyDetailsState";
import { CompanyBizWebDetails } from "../models/companyBizWebDetails";
import { fieldMap } from "./fieldMap";

export function updateFieldFromBNXT(field: Fields, bnxtInfo: CompanyInfo, updatedCompanyInfo: CompanyInfo) {
    const property = fieldMap[field];

    updatedCompanyInfo[property.bnxt] = {
        canChange: updatedCompanyInfo[property.bnxt].canChange,
        value: updatedCompanyInfo[property.bnxt].value as string & number & boolean
    };

    return updatedCompanyInfo;
}

export function updateFieldFromBizWeb(field: Fields, bizWebDetails: CompanyBizWebDetails, updatedCompanyInfo: CompanyInfo) {
    const property = fieldMap[field];

    updatedCompanyInfo[property.bnxt] = {
        canChange: updatedCompanyInfo[property.bnxt].canChange,
        value: bizWebDetails[property.bizweb] as string & number & boolean
    };

    return updatedCompanyInfo;
}