import styles from './Loading.module.css';

interface LoadingComponentProps {
    spinnerText?: string | null;
}

export default function LoadingComponent(props: LoadingComponentProps){
    return (
        <div className={`${styles.container}`}>
            <div className={`${styles.flex}`}>
                <span aria-label='loading-spinner' className='spinner spinner-default-green'></span>
                <div aria-label='loading-text'>
                    {props.spinnerText}
                </div>
            </div>
        </div>);
}
