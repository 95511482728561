import { useState, useEffect, ReactNode } from "react";
import * as React from "react";
import LoadingOverlayComponent from "../../components/loading/LoadingComponent";
import { ConfigContainer } from "./ConfigContextInterface";

const emptyConfigContainer: ConfigContainer = {
    backendApiUrl: '',
    oidcConfiguration: {
        authority: '',
        clientId: '',
        scope: ''
    }
}

export const ConfigContext = React.createContext<ConfigContainer>(emptyConfigContainer);
export const ConfigProvider = ({ children } : {children: ReactNode}) => {
    const [config, setConfig] = useState<ConfigContainer>();

    useEffect(() => {
        if (config) {
            return;
        } 

        if (shouldRenewCachedConfig()) {
            fetch('/config.json')
                .then(response => response.json())
                .then(responseJson => {
                    setCachedDeploymentName();
                    const configContainer = (responseJson as ConfigContainer);
                    setCachedConfig(configContainer);
                    setConfig(configContainer);
                })
        } 
        else {
            const cachedConfig = getCachedConfig();
            setConfig(cachedConfig);
        }
    }, [config]);

    if (!config) {
        return (
            <LoadingOverlayComponent spinnerText="Loading config"/>
        );
    }

    return (
        <ConfigContext.Provider value = {config}>
            { children }
        </ConfigContext.Provider>
    )
}

function getCachedConfig() : ConfigContainer | undefined {
    const cachedConfigJson = sessionStorage.getItem('bizweb.cachedConfig') ?? '';
    const configContainer = JSON.parse(cachedConfigJson);

    return configContainer;
}

function shouldRenewCachedConfig() : boolean {
    const serverDeploymentName = getServerDeploymentName();
    const cachedDeploymentName = getCachedDeploymentName();

    if (serverDeploymentName === '' || cachedDeploymentName === '' || serverDeploymentName !== cachedDeploymentName) {
        return true;
    }

    return false;
}

function getServerDeploymentName() : string {
    const hostname = process.env.HOSTNAME;

    if (!hostname) {
        return '';
    }

    const deploymentAndPodName = hostname?.replace('bizweb', '');
    const deploymentName = deploymentAndPodName?.split('-')[0];

    return deploymentName;
}

function getCachedDeploymentName() : string {
    return sessionStorage.getItem('bizweb.deploymentName') ?? '';
}

function setCachedDeploymentName(): void {
    const serverDeploymentName = getServerDeploymentName();
    sessionStorage.setItem('bizweb.deploymentName', serverDeploymentName);
}

function setCachedConfig(config: ConfigContainer) : void {
    const configJson = JSON.stringify(config);
    sessionStorage.setItem('bizweb.cachedConfig', configJson);
}

